// Import necessary dependencies and components
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

// LabourAndBirth component for labour and birth resources page
const LabourAndBirth = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header component */}
      <Header />
      
      {/* Main content area */}
      <main className="flex-grow container mx-auto p-4">
        <h1 className="text-4xl font-bold text-center my-8">Labour and Birth Resources</h1>
        <p className="text-lg my-4 text-center">
          Explore comprehensive resources to support you during labour and birth. Below, you will find information about labour stages, pain relief, induction, and more.
        </p>

        {/* Labour and Birth Resources Section */}
        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Labour Stages</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/labour-birth" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Labour & Birth at BC Women's Hospital
              </a>
            </li>
            <li>
              <a href="https://www.healthlinkbc.ca/pregnancy-parenting/labour-and-birth" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Labour and Birth Overview - HealthLink BC
              </a>
            </li>
            <li>
              <a href="https://www.healthlinkbc.ca/pregnancy-parenting/labour-and-birth/stages-labour/telling-pre-labour-and-true-labour-part" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Telling Pre-Labour and True Labour Apart
              </a>
            </li>
            <li>
              <a href="/Assets/BCW_EarlyLabour.pdf" className="text-blue-500 hover:underline" download>
                Early Labour (PDF)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Pain Relief Options</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="/Assets/BCW923_PainReliefInLabour.pdf" className="text-blue-500 hover:underline" download>
                Pain Relief in Labour (PDF)
              </a>
            </li>
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/labour-birth/pain-relief-in-labour#During--active--labour" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Pain Relief During Active Labour
              </a>
            </li>
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/labour-birth/pain-relief-in-labour#Epidurals" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Epidurals for Pain Relief
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Labour Induction</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="/Assets/BC-Womens-Inducing-Your-Labour.pdf" className="text-blue-500 hover:underline" download>
                Inducing Your Labour (PDF)
              </a>
            </li>
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/labour-birth/inducing-your-labour" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Information on Labour Induction at BC Women's Hospital
              </a>
            </li>
            <li>
              <a href="/Assets/When-your-water-breaks-before-labour-English.pdf" className="text-blue-500 hover:underline" download>
                When Your Water Breaks Before Labour (PDF)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Types of Birth</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.bcwomens.ca/health-info/pregnancy-parenting/types-of-birth" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Types of Birth - BC Women's Hospital
              </a>
            </li>
            <li>
              <a href="/Assets/Assisted-Vaginal-Birth-Handout-V1.4-1.pdf" className="text-blue-500 hover:underline" download>
                Assisted Vaginal Birth Handout (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/Comfort and Posture During Pregnancy - BCW594_ComfortPosture_2014.pdf" className="text-blue-500 hover:underline" download>
                Comfort and Posture During Pregnancy (PDF)
              </a>
            </li>
            <li>
              <a href="https://www.cmaj.ca/content/cmaj/181/6-7/377.full.pdf" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Outcomes of Planned Home Birth vs. Planned Hospital Birth (Canadian Study)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Hospital Information and Preparation</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/planning-to-give-birth-at-bc-womens/take-a-virtual-tour" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Giving Birth at BC Women’s: What to Expect - Virtual Tour
              </a>
            </li>
            <li>
              <a href="/Assets/SPH Birthing unite.pdf" className="text-blue-500 hover:underline" download>
                St. Paul's Hospital Birthing Unit (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/What to pack for labour - Hospital.pdf" className="text-blue-500 hover:underline" download>
                What to Pack for Labour - Hospital Checklist (PDF)
              </a>
            </li>
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/planning-to-give-birth-at-bc-womens" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                BC Women's Hospital Family Information Package
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Emergency Information</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="/Assets/CMCEmergency.pdf" className="text-blue-500 hover:underline" download>
                CMC Emergency (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/Common Assessment of L&D.pdf" className="text-blue-500 hover:underline" download>
                Common Assessment of Labour & Delivery (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/KT-When-your-pregnancy-goes-past-your-due-date-English.pdf" className="text-blue-500 hover:underline" download>
                When Your Pregnancy Goes Past Your Due Date (PDF)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">VBAC Resources</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="https://www.powertopush.ca" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Power to Push Campaign BC Women's Hospital
              </a>
            </li>
            <li>
              <a href="https://optimalbirthbc.ca/wp-content/uploads/resources/for-bc-health-practitioners/brochures-vbac/OptimalBirth_Brochure.pdf" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Optimal Birth BC VBAC Brochure (PDF)
              </a>
            </li>
          </ul>
        </section>
      </main>
      
      {/* Footer component */}
      <Footer />
    </div>
  );
};

export default LabourAndBirth;