import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Resources from './pages/Resources';
import PostpartumCare from './pages/postpartumcare';
import PregnancyStages from './pages/pregnancystages';
import PrenatalCare from './pages/prenatalcare';
import LabourAndBirth from './pages/labourandbirth';
import SupportAndResources from './pages/supportandresources';
// src/index.js
import './App.css';  // or './index.css', depending on what you're using



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/postpartumcare" element={<PostpartumCare />} />
        <Route path="/pregnancystages" element={<PregnancyStages />} />
        <Route path="/prenatalcare" element={<PrenatalCare />} /> {/* Route for PrenatalCare page */}
        <Route path="/labourandbirth" element={<LabourAndBirth />} />
        <Route path="/supportandresources" element={<SupportAndResources />} />

      </Routes>
    </Router>
  );
};

export default App;
