// Import necessary dependencies and components
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

// PrenatalCare component for prenatal care resources page
const PrenatalCare = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header component */}
      <Header />
      
      {/* Main content area */}
      <main className="flex-grow container mx-auto p-4">
        <h1 className="text-4xl font-bold text-center my-8">Prenatal Care Resources</h1>
        <p className="text-lg my-4 text-center">
          Discover a comprehensive list of prenatal care resources to guide you throughout your pregnancy. This section provides helpful information on screenings, health tips, and more.
        </p>
        
        {/* Prenatal Care Resources Section */}
        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Prenatal Genetic Screening</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.perinatalservicesbc.ca/our-services/screening-programs/prenatal-genetic-screening" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Prenatal Genetic Screening Overview
              </a>
            </li>
            <li>
              <a href="http://www.perinatalservicesbc.ca/our-services/screening-programs/prenatal-genetic-screening/funded-prenatal-genetic-screening" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Funded Prenatal Genetic Screening
              </a>
            </li>
            <li>
              <a href="http://www.perinatalservicesbc.ca/health-professionals/professional-resources/screening/prenatal-genetic/non-invasive-prenatal-screening-nips" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Non-Invasive Prenatal Screening (NIPS)
              </a>
            </li>
            <li>
              <a href="https://www.lifelabsgenetics.com/product/non-invasive-prenatal-testing/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Panorama Non-Invasive Prenatal Testing (NIPT)
              </a>
            </li>
            <li>
              <a href="http://pegasus-pegase.ca/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Pegasus-2 Project
              </a>
            </li>
            <li>
              <a href="https://www.pacificfertility.ca/?page_id=17" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Pacific Centre for Reproductive Medicine
              </a>
            </li>
            <li>
              <a href="/Assets/Diabetes & Pregnancy.pdf" className="text-blue-500 hover:underline" download>
                Diabetes & Pregnancy (PDF)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Nutrition and Supplements</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="https://havingababy.co/vitamin-d-and-new-research-findings" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Vitamin D and New Research Findings
              </a>
            </li>
            <li>
              <a href="https://food-guide.canada.ca/en/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Canada's Food Guide
              </a>
            </li>
            <li>
              <a href="https://www.canada.ca/en/health-canada/services/food-safety-vulnerable-populations/food-safety-pregnant-women.html" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Food Safety in Pregnancy
              </a>
            </li>
            <li>
              <a href="https://www.options.bc.ca/program/healthiest-babies-possible" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Healthiest Babies Possible
              </a>
            </li>
            <li>
              <a href="https://www.healthlinkbc.ca/pregnancy-parenting" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Folic Acid and Healthy Eating for Pregnancy
              </a>
            </li>
            <li>
              <a href="/Assets/Iron-Deficiency-Anemia-in-Pregnancy.pdf" className="text-blue-500 hover:underline" download>
                Iron Deficiency Anemia in Pregnancy (PDF)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Exercise and Physical Activity</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.bcwomens.ca/health-info/pregnancy-parenting/exercise-pregnancy" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Active Living Before, During, and After Pregnancy
              </a>
            </li>
            <li>
              <a href="/Assets/FIT PREGNANCY - exercisebasics.pdf" className="text-blue-500 hover:underline" download>
                Fit Pregnancy - Exercise Basics (PDF)
              </a>
            </li>
            <li>
              <a href="https://www.acog.org/womens-health/faqs/exercise-during-pregnancy?utm_source=redirect&utm_medium=web&utm_campaign=otn" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Exercise During Pregnancy - ACOG Guidelines
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Mental Health and Wellbeing</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="https://postpartum.org" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Pacific Postpartum Support Society
              </a>
            </li>
            <li>
              <a href="http://www.coastcounselling.ca/barb-komar.html" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Barb Komar, Pregnancy and Loss Counsellor
              </a>
            </li>
            <li>
              <a href="http://www.tzcounselling.com/index.htm" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Tania Zulkoskey Counselling
              </a>
            </li>
            <li>
              <a href="https://drregev.com" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Dr. Michal Regev, Registered Psychologist
              </a>
            </li>
            <li>
              <a href="https://www.dragonstonecounselling.ca" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Dragonstone Counselling
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Vaccinations and Immunization</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="https://immunizebc.ca/adults/pregnancy#During%20pregnancy" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Immunization During Pregnancy - Immunize BC
              </a>
            </li>
            <li>
              <a href="/Assets/Tdap-Vaccination-During-Pregnancy.pdf" className="text-blue-500 hover:underline" download>
                Tdap Vaccination During Pregnancy (PDF)
              </a>
            </li>
            <li>
              <a href="http://www.bccdc.ca/health-info/diseases-conditions/covid-19/covid-19-vaccine/vaccine-considerations" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                COVID-19 Vaccine Considerations During Pregnancy
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Prenatal Classes and Birth Planning</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://birthdocs.ca/resources_items/childbirth-classes-in-vancouver/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Childbirth Classes in Vancouver
              </a>
            </li>
            <li>
              <a href="https://www.maternityhub.ca/home/virtual-classes" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Virtual Prenatal Classes and Resources
              </a>
            </li>
            <li>
              <a href="/Assets/SPH PN class.pdf" className="text-blue-500 hover:underline" download>
                St. Paul's Hospital Prenatal Class (PDF)
              </a>
            </li>
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/planning-to-give-birth-at-bc-womens/take-a-virtual-tour" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Giving Birth at BC Women’s: What to Expect - Virtual Tour
              </a>
            </li>
          </ul>
        </section>
      </main>
      
      {/* Footer component */}
      <Footer />
    </div>
  );
};

export default PrenatalCare;