import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import OceanImage from '../assets/ocean.jpeg'; // Import the ocean background image

const Contact = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);

  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [registrationForm, setRegistrationForm] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    preferredName: '',
    dob: '',
    email: '',
    phoneNumber: '',
    bcCareCard: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    buzzerNumber: '',
    partnerInfo: '',
    dueDate: '',
    lastPeriod: '',
    healthConditions: '',
    birthPlace: '',
    birthHistory: '',
    losses: '',
    familyDoctor: '',
    careProvider: '',
    otherNotes: '',
    midwifeReferral: '',
  });

  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactForm({
      ...contactForm,
      [name]: value,
    });
  };

  const handleRegistrationChange = (e) => {
    const { name, value } = e.target;
    setRegistrationForm({
      ...registrationForm,
      [name]: value,
    });
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:feizarshia@gmail.com?subject=Contact%20Form%20Submission%20from%20${contactForm.name}&body=Name:%20${contactForm.name}%0AEmail:%20${contactForm.email}%0AMessage:%20${contactForm.message}`;
    window.location.href = mailtoLink;
  };

  const handleRegistrationSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:feizarshia@gmail.com?subject=Registration%20Form%20Submission%20from%20${registrationForm.firstName}%20${registrationForm.lastName}&body=First%20Name:%20${registrationForm.firstName}%0ALast%20Name:%20${registrationForm.lastName}%0AMiddle%20Name:%20${registrationForm.middleName}%0APreferred%20Name%20and%20Pronouns:%20${registrationForm.preferredName}%0ADate%20of%20Birth:%20${registrationForm.dob}%0AEmail:%20${registrationForm.email}%0APhone%20Number:%20${registrationForm.phoneNumber}%0ABC%20Care%20Card%20Number:%20${registrationForm.bcCareCard}%0AAddress:%20${registrationForm.address}%2C%20${registrationForm.city}%2C%20${registrationForm.state}%2C%20${registrationForm.postalCode}%0ABuzzer%20Number:%20${registrationForm.buzzerNumber}%0APartner%20Info:%20${registrationForm.partnerInfo}%0ADue%20Date:%20${registrationForm.dueDate}%0AFirst%20Day%20of%20Last%20Menstrual%20Period:%20${registrationForm.lastPeriod}%0AHealth%20Conditions:%20${registrationForm.healthConditions}%0APreferred%20Birth%20Place:%20${registrationForm.birthPlace}%0ABirth%20History:%20${registrationForm.birthHistory}%0ALosses:%20${registrationForm.losses}%0AFamily%20Doctor:%20${registrationForm.familyDoctor}%0ACare%20Provider:%20${registrationForm.careProvider}%0AOther%20Notes:%20${registrationForm.otherNotes}%0AMidwife%20Referral:%20${registrationForm.midwifeReferral}`;
    window.location.href = mailtoLink;
  };

  return (
    <div style={{ backgroundImage: `url(${OceanImage})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>
      <Header />

      {/* Contact Section */}
      <div className="container mx-auto py-12 px-4">
        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '10px', padding: '2rem', marginBottom: '2rem' }}>
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-8 text-gray-800">GET IN TOUCH</h1>
          <div className="w-full md:w-1/2 mx-auto mb-10 text-center">
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Contact Information</h2>
            <p className="mb-2 text-gray-800">
              <strong>Email:</strong> info@yourpractice.com
            </p>
            <p className="mb-2 text-gray-800">
              <strong>Phone:</strong> 604-363-8158
            </p>
            <p className="text-red-600 mb-4">
              <strong>Pager Number:</strong> 18669060165 <span className="text-red-600">(Only for urgent matters if you are already a client)</span>
            </p>
          </div>
          <div className="flex flex-wrap justify-center items-center">
            <div className="flex flex-col items-center gap-4">
              <button
                onClick={() => {
                  setShowContactForm(true);
                  setShowRegistrationForm(false);
                }}
                className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded w-48"
              >
                Contact Us
              </button>
              <button
                onClick={() => {
                  setShowRegistrationForm(true);
                  setShowContactForm(false);
                }}
                className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded w-48"
              >
                Register
              </button>
            </div>
          </div>
        </div>

        {showContactForm && (
          <div className="flex justify-center mt-10">
            <form onSubmit={handleContactSubmit} className="bg-white shadow-md rounded px-6 pt-6 pb-8 mb-4 w-full md:w-1/2">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={contactForm.name}
                  onChange={handleContactChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={contactForm.email}
                  onChange={handleContactChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="you@example.com"
                  required
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  value={contactForm.message}
                  onChange={handleContactChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  rows="4"
                  placeholder="Your message..."
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        )}

        {showRegistrationForm && (
          <div className="flex justify-center mt-10">
            <form onSubmit={handleRegistrationSubmit} className="bg-white shadow-md rounded px-6 pt-6 pb-8 mb-4 w-full md:w-3/4">
              <h2 className="text-2xl font-bold mb-4">Registration Form</h2>
              <p className="text-sm mb-6">Fields with (*) are required</p>
              {/* Registration Form Fields */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                  First Name*
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={registrationForm.firstName}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                  Last Name*
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={registrationForm.lastName}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="middleName">
                  Middle Name (Optional)
                </label>
                <input
                  type="text"
                  name="middleName"
                  id="middleName"
                  value={registrationForm.middleName}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="preferredName">
                  Preferred Name and Pronouns
                </label>
                <input
                  type="text"
                  name="preferredName"
                  id="preferredName"
                  value={registrationForm.preferredName}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dob">
                  What is your date of birth?*
                </label>
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  value={registrationForm.dob}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
                <p className="text-xs mt-1">Note: To do it faster first set day and month and click OK, then change the year manually.</p>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={registrationForm.email}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="example@example.com"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                  Phone Number*
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={registrationForm.phoneNumber}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Please enter a valid phone number."
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bcCareCard">
                  What is your BC Care Card number? (PHN)*
                </label>
                <input
                  type="text"
                  name="bcCareCard"
                  id="bcCareCard"
                  value={registrationForm.bcCareCard}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                  Address*
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  value={registrationForm.address}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Street Address"
                  required
                />
                <input
                  type="text"
                  name="addressLine2"
                  id="addressLine2"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
                  placeholder="Street Address Line 2"
                />
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={registrationForm.city}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
                  placeholder="City"
                  required
                />
                <input
                  type="text"
                  name="state"
                  id="state"
                  value={registrationForm.state}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
                  placeholder="State / Province"
                  required
                />
                <input
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  value={registrationForm.postalCode}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2"
                  placeholder="Postal / Zip Code"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="buzzerNumber">
                  Please list your buzzer number if you have one for your home address
                </label>
                <input
                  type="text"
                  name="buzzerNumber"
                  id="buzzerNumber"
                  value={registrationForm.buzzerNumber}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="partnerInfo">
                  If you have a partner, what is their name and phone number?
                </label>
                <input
                  type="text"
                  name="partnerInfo"
                  id="partnerInfo"
                  value={registrationForm.partnerInfo}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dueDate">
                  Estimated due date (if known)
                </label>
                <input
                  type="date"
                  name="dueDate"
                  id="dueDate"
                  value={registrationForm.dueDate}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <p className="text-xs mt-1">Note: To do it faster first set day and month and click OK, then change the year manually.</p>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastPeriod">
                  First day of your last menstrual period:*
                </label>
                <input
                  type="date"
                  name="lastPeriod"
                  id="lastPeriod"
                  value={registrationForm.lastPeriod}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
                <p className="text-xs mt-1">Note: To do it faster first set day and month and click OK, then change the year manually.</p>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="healthConditions">
                  Do you have any major health conditions?*
                </label>
                <textarea
                  name="healthConditions"
                  id="healthConditions"
                  value={registrationForm.healthConditions}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="birthPlace">
                  What is your preferred choice of birth place?*
                </label>
                <input
                  type="text"
                  name="birthPlace"
                  id="birthPlace"
                  value={registrationForm.birthPlace}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="birthHistory">
                  Have you given birth before?*
                </label>
                <textarea
                  name="birthHistory"
                  id="birthHistory"
                  value={registrationForm.birthHistory}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="losses">
                  Have you had any losses?
                </label>
                <textarea
                  name="losses"
                  id="losses"
                  value={registrationForm.losses}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="familyDoctor">
                  Do you have a family doctor? If so, what is their name and office phone number?
                </label>
                <input
                  type="text"
                  name="familyDoctor"
                  id="familyDoctor"
                  value={registrationForm.familyDoctor}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="careProvider">
                  Have you already seen a care provider who ordered blood work and/or an ultrasound?
                </label>
                <textarea
                  name="careProvider"
                  id="careProvider"
                  value={registrationForm.careProvider}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="otherNotes">
                  Other notes
                </label>
                <textarea
                  name="otherNotes"
                  id="otherNotes"
                  value={registrationForm.otherNotes}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="midwifeReferral">
                  Were you referred to a specific midwife by a previous client of ours? Do you have any unique needs we should know about? Do you plan to move during pregnancy? If so, where to?
                </label>
                <textarea
                  name="midwifeReferral"
                  id="midwifeReferral"
                  value={registrationForm.midwifeReferral}
                  onChange={handleRegistrationChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <button
                type="submit"
                className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              >
                Submit Registration
              </button>
            </form>
          </div>
        )}
      </div>

      {/* Main Footer Component */}
      <Footer />
    </div>
  );
};

export default Contact;
