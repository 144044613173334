import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer'; // Import the Footer component
import { Link } from 'react-router-dom';
import prenatalCareImage from '../assets/prenatalcare.jpeg'; // Import the prenatal care image
import deliveryImage from '../assets/delivery.jpeg'; // Import the delivery image
import postnatalCareImage from '../assets/postnatalcare.jpeg'; // Import the postnatal care image
import OceanImage from '../assets/ocean.jpeg'; // Import the ocean background image

const Services = () => {
  return (
    <div style={{ backgroundImage: `url(${OceanImage})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>
      <Header /> {/* Including the Header component */}
      
      {/* Our Services Section */}
      <section id="our-services" className="py-12 md:py-20 text-gray-800">
        <div className="container mx-auto text-center px-6">
          <h2 className="text-3xl md:text-4xl font-bold mb-10">OUR SERVICES</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">

            {/* Service 1: Prenatal Care */}
            <div
              className="rounded-lg shadow-lg flex flex-col h-full"
              style={{ backgroundImage: `url(${prenatalCareImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
              <div className="py-6 px-6 rounded-t-lg" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <h3 className="text-xl font-bold text-white">PRENATAL CARE</h3>
                <p className="text-lg font-medium mt-4 text-white">Comprehensive care throughout your pregnancy journey.</p>
              </div>
              <div className="flex-grow px-6 py-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <ul className="list-disc list-inside text-left text-white">
                  <li>Regular health check-ups and monitoring</li>
                  <li>Personalized nutrition and wellness plans</li>
                  <li>Emotional and psychological support</li>
                  <li>Educational resources and childbirth preparation</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <Link to="/contact" className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                  CONTACT US FOR MORE INFO
                </Link>
              </div>
            </div>

            {/* Service 2: Labor and Delivery Support */}
            <div
              className="rounded-lg shadow-lg flex flex-col h-full"
              style={{ backgroundImage: `url(${deliveryImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
              <div className="py-6 px-6 rounded-t-lg" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <h3 className="text-xl font-bold text-white">LABOR & DELIVERY SUPPORT</h3>
                <p className="text-lg font-medium mt-4 text-white">Guiding you through every step of labor and delivery.</p>
              </div>
              <div className="flex-grow px-6 py-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <ul className="list-disc list-inside text-left text-white">
                  <li>Personalized birth plans</li>
                  <li>Continuous support during labor</li>
                  <li>Natural and medically-assisted delivery options</li>
                  <li>Post-delivery care and immediate newborn assistance</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <Link to="/contact" className="inline-block bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
                  CONTACT US FOR MORE INFO
                </Link>
              </div>
            </div>

            {/* Service 3: Postnatal Care */}
            <div
              className="rounded-lg shadow-lg flex flex-col h-full"
              style={{ backgroundImage: `url(${postnatalCareImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
              <div className="py-6 px-6 rounded-t-lg" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <h3 className="text-xl font-bold text-white">POSTNATAL CARE</h3>
                <p className="text-lg font-medium mt-4 text-white">Ensuring your well-being and your baby's health after birth.</p>
              </div>
              <div className="flex-grow px-6 py-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <ul className="list-disc list-inside text-left text-white">
                  <li>Postpartum check-ups</li>
                  <li>Breastfeeding support and education</li>
                  <li>Emotional and mental health support</li>
                  <li>Infant care and development guidance</li>
                </ul>
              </div>
              <div className="px-6 pt-4 pb-6" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <Link to="/contact" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  CONTACT US FOR MORE INFO
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Use the actual Footer component */}
      <Footer />
    </div>
  );
};

export default Services;
