// src/pages/About.js

import React from 'react';
import Header from '../components/Header'; // Ensure your Header component is correctly referenced
import MitraImage from '../assets/Mitra.png'; // Import Mitra's image
import Footer from '../components/Footer'; // Import the shared Footer component
import OceanImage from '../assets/ocean.jpeg'; // Import the ocean background image

const About = () => {
  return (
    <div style={{ backgroundImage: `url(${OceanImage})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>
      <Header /> {/* Including the Header component */}
      
      <div className="container mx-auto py-8 px-4 md:py-16 md:px-8">
        {/* Title Section in a Box */}
        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '10px', padding: '2rem', marginBottom: '2rem' }}>
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-4 md:mb-8 text-gray-800">About Us</h2>
          <p className="text-center text-base md:text-lg mb-8 md:mb-16 text-gray-800">
            Learn more about our practice and the dedicated professionals behind it.
          </p>
        </div>

        {/* Who We Are Section */}
        <div className="flex flex-col items-center mb-12 md:mb-16">
          {/* Mitra's Information */}
          <img 
            src={MitraImage} 
            alt="Mitra [Last Name]" 
            className="w-full max-w-md h-auto rounded-lg mb-4" 
            style={{ objectFit: 'contain' }} 
          />
          <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '10px', padding: '2rem', marginTop: '1rem', maxWidth: '600px', textAlign: 'center' }}>
            <h3 className="text-lg md:text-2xl font-semibold text-gray-800">Mitra Sadeghipour</h3>
            <p className="text-gray-600">Certified Midwife</p>
            <p className="text-sm md:text-base mt-2 text-gray-800">
              Mitra earned her B.Sc. in Midwifery from the International Shiraz University of Medical Sciences in Iran. She practiced midwifery in Iran from 1990 to 2002, conducting over 1,000 deliveries. In 2004, Mitra became registered with the College of Nurses of Ontario (CNO) and worked as a Registered Nurse at the labor and delivery ward in Etobicoke General Hospital for a year to familiarize herself with the Canadian healthcare model. She successfully completed the International Midwifery Pre-Registration program, further enhancing her expertise and commitment to providing compassionate and evidence-based care.
            </p>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <Footer /> {/* Include the shared Footer component */}
    </div>
  );
};

export default About;
