// Import necessary dependencies and components
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

// PregnancyStages component for the first page of our website
const PregnancyStages = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header component */}
      <Header />
      
      {/* Main content area */}
      <main className="flex-grow container mx-auto p-4">
        <h1 className="text-4xl font-bold text-center my-8">Pregnancy Stages</h1>
        <p className="text-lg my-4 text-center">
          Welcome to the Pregnancy Stages section. Here, you will find valuable resources to help you through each step of your pregnancy journey.
        </p>
        
        {/* Pregnancy Stages Links Section */}
        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">0-10 Weeks</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.perinatalservicesbc.ca/our-services/screening-programs/prenatal-genetic-screening" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Prenatal Genetic Screening
              </a>
            </li>
            <li>
              <a href="http://www.perinatalservicesbc.ca/our-services/screening-programs/prenatal-genetic-screening/funded-prenatal-genetic-screening" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Funded Prenatal Genetic Screening
              </a>
            </li>
            <li>
              <a href="http://www.perinatalservicesbc.ca/health-professionals/professional-resources/screening/prenatal-genetic/non-invasive-prenatal-screening-nips" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Non-Invasive Prenatal Screening (NIPS)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">10-20 Weeks</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="/Assets/Immunization and pregnancy.pdf" className="text-blue-500 hover:underline" download>
                Immunization and Pregnancy (PDF)
              </a>
            </li>
            <li>
              <a href="https://smartparentcanada.ca" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                SmartMom - Pregnancy Support
              </a>
            </li>
            <li>
              <a href="/Assets/Getting-Your-LNG-IUD-Inserted-V1.3.pdf" className="text-blue-500 hover:underline" download>
                Getting Your LNG-IUD Inserted (PDF)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">20-28 Weeks GA</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.bcwomens.ca/health-info/pregnancy-parenting/what-to-expect-in-pregnancy#First--7--months" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                What to Expect in Pregnancy
              </a>
            </li>
            <li>
              <a href="/Assets/Preterm Labour _ HealthLink BC.pdf" className="text-blue-500 hover:underline" download>
                Preterm Labour (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/High Blood Pressure During Pregnancy _ HealthLink BC.pdf" className="text-blue-500 hover:underline" download>
                High Blood Pressure During Pregnancy (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/Diabetes & Pregnancy.pdf" className="text-blue-500 hover:underline" download>
                Diabetes & Pregnancy (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/Pregnancy_ Kick Counts _ HealthLink BC.pdf" className="text-blue-500 hover:underline" download>
                Fetal Movement Monitoring - Kick Counts (PDF)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">28-34 Weeks GA</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="/Assets/HDP-What-do-I-need-to-know-English.pdf" className="text-blue-500 hover:underline" download>
                High Blood Pressure During Pregnancy - What You Need to Know (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/GBS-in-pregnancy-2022-English.pdf" className="text-blue-500 hover:underline" download>
                GBS in Pregnancy (PDF)
              </a>
            </li>
            <li>
              <a href="https://www.healthlinkbc.ca/healthlinkbc-files/tetanus-diphtheria-pertussis-tdap-vaccine" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Tetanus, Diphtheria, Pertussis (Tdap) Vaccine
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">34-41 Weeks GA</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/labour-birth" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Labour & Birth at BC Women’s
              </a>
            </li>
            <li>
              <a href="/Assets/35 wks information.pdf" className="text-blue-500 hover:underline" download>
                35 Weeks Information (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/BC-Womens-Inducing-Your-Labour.pdf" className="text-blue-500 hover:underline" download>
                Inducing Your Labour (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/BCW923_PainReliefInLabour.pdf" className="text-blue-500 hover:underline" download>
                Pain Relief in Labour (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/When-your-water-breaks-before-labour-English.pdf" className="text-blue-500 hover:underline" download>
                When Your Water Breaks Before Labour (PDF)
              </a>
            </li>
          </ul>
        </section>

        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">After Birth</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/after-the-birth" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                After-Birth Care for You and Your Baby
              </a>
            </li>
            <li>
              <a href="/Assets/Breastfeeding Clinic.pdf" className="text-blue-500 hover:underline" download>
                Breastfeeding Clinic (PDF)
              </a>
            </li>
            <li>
              <a href="https://www.healthlinkbc.ca/pregnancy-parenting/parenting-babies-0-12-months/breastfeeding/breastfeeding" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Breastfeeding Information
              </a>
            </li>
            <li>
              <a href="/Assets/Postpartum-Contraception.pdf" className="text-blue-500 hover:underline" download>
                Postpartum Contraception (PDF)
              </a>
            </li>
          </ul>
        </section>
      </main>
      
      {/* Footer component */}
      <Footer />
    </div>
  );
};

export default PregnancyStages;
