import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DeliveryImage from '../assets/delivery.jpeg';
import PrenatalImage from '../assets/prenatal.jpeg';
import PostnatalImage from '../assets/postnatal.jpeg';
import HeroBackgroundImage from '../assets/stages.jpeg';
import SupportImage from '../assets/support.jpeg';

const Home = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header */}
      <Header />

      {/* Hero Section */}
      <section
        id="hero"
        className="relative py-20 md:py-32 bg-cover bg-center text-white flex items-center"
        style={{ backgroundImage: `url(${HeroBackgroundImage})` }}
      >
        <div className="container mx-auto px-6 md:px-12 text-center">
          <div className="bg-gradient-to-b from-gray-800/70 to-transparent p-10 rounded-xl shadow-xl">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight">
              Nurturing You Every Step of the Way
            </h1>
            <p className="mt-4 text-lg md:text-2xl max-w-3xl mx-auto">
              Providing compassionate care for your pregnancy, birth, and post-birth journey.
            </p>
            <Link
              to="/contact"
              className="inline-block mt-8 bg-emerald-600 text-white font-semibold py-4 px-10 rounded-full transition-transform transform hover:scale-105 hover:bg-emerald-800 duration-300"
            >
              Get in Touch
            </Link>
          </div>
        </div>
      </section>

      {/* Our Services Section - Highlighted Early */}
      <section id="our-services" className="py-16 md:py-24 bg-white">
        <div className="container mx-auto px-6 md:px-12">
          <h2 className="text-4xl font-bold mb-12 text-center text-emerald-800">How We Support You</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
            {/* Service Cards */}
            {[
              { img: PrenatalImage, title: 'Prenatal Care', description: 'Holistic prenatal support including regular health assessments, personalized wellness guidance, and emotional care.' },
              { img: DeliveryImage, title: 'Labor & Delivery Assistance', description: 'Comprehensive support during labor and delivery, personalized birthing plans, and continuous care to ensure a positive birth experience.' },
              { img: PostnatalImage, title: 'Post-Birth Support', description: 'Focus on your well-being and newborn care, offering postpartum assessments, breastfeeding assistance, and infant care guidance.' },
            ].map((service, index) => (
              <div key={index} className="bg-white rounded-2xl shadow-lg overflow-hidden transition-transform transform hover:scale-105 duration-300">
                <img src={service.img} alt={service.title} className="object-cover w-full h-56" />
                <div className="p-8">
                  <h3 className="text-2xl font-semibold mb-4 text-emerald-800">{service.title}</h3>
                  <p className="text-lg text-gray-700">{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Our Mission Section */}
      <section id="our-mission" className="py-16 md:py-24 bg-gray-100">
        <div className="container mx-auto px-6 md:px-12 flex flex-col lg:flex-row items-center gap-16">
          <img src={SupportImage} alt="Support" className="rounded-2xl shadow-lg w-full lg:w-1/2 object-cover" />
          <div className="lg:w-1/2 text-center lg:text-left">
            <h2 className="text-4xl font-bold mb-6 text-emerald-800">Our Mission</h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              We are committed to supporting expectant parents by providing comprehensive resources and care throughout pregnancy, birth, and beyond. Our goal is to foster a nurturing, inclusive environment that celebrates all families.
            </p>
          </div>
        </div>
      </section>

      {/* Meet Our Team Section */}
      <section id="meet-our-team" className="py-16 md:py-24 bg-gray-50">
        <div className="container mx-auto px-6 md:px-12 text-center">
          <h2 className="text-4xl font-bold mb-12 text-emerald-800">Meet Our Dedicated Team</h2>
          <p className="text-lg text-gray-700 max-w-2xl mx-auto mb-8">
            Our team of compassionate midwives and healthcare professionals is here to support you every step of the way.
          </p>
          <Link
            to="/about"
            className="inline-block bg-emerald-600 text-white font-semibold py-4 px-10 rounded-full transition-transform transform hover:scale-105 duration-300"
          >
            Learn More About Us
          </Link>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
