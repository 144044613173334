// Import necessary dependencies and components
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const PostpartumCare = () => {
  const resources = [
    {
      title: "Chest or Breastfeeding Info and Resources",
      items: [
        { title: "Breastfeeding Starting Out Right by Dr. Jack Newman", link: "https://ibconline.ca/information-sheets/breastfeeding-starting-out-right/" },
        { title: "La Leche League Canada", link: "https://www.lllc.ca" },
        { title: "KellyMom: Evidence-based Breastfeeding and Parenting", link: "https://kellymom.com" },
        { title: "World Health Organization Infant Feeding Recommendation", link: "https://www.who.int/news-room/fact-sheets/detail/infant-and-young-child-feeding" },
        { title: "Breastfeeding Your Baby", link: "http://www.bcwomens.ca/health-info/pregnancy-parenting/breastfeeding-your-baby" },
        { title: "Breastfeeding Clinic", link: "http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/breastfeeding-clinic#About" },
        { title: "THE NEWMAN BREASTFEEDING CLINIC- INTERNATIONAL BREASTFEEDING CENTRE", link: "https://ibconline.ca/information-sheets/" },
        { title: "Tips for Common Breastfeeding Challenges - Vancouver Coastal Health (PDF)", link: "/Assets/Tips for common breastfeeding challenges _ Vancouver Coastal Health.pdf", isDownloadable: true }
      ]
    },
    {
      title: "Drinking Alcohol While Chest or Breastfeeding",
      items: [
        { title: "La Leche League International - Alcohol and Breastfeeding", link: "https://llli.org/breastfeeding-info/alcohol/" }
      ]
    },
    {
      title: "Milk Banks",
      items: [
        { title: "BC Women's Hospital Milk Bank", link: "http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/milk-bank" },
        { title: "Human Milk Banking Association of North America", link: "https://www.hmbana.org" },
        { title: "Human Milk 4 Human Babies Network", link: "http://www.hm4hb.net" }
      ]
    },
    {
      title: "Chest or Breastfeeding Videos",
      items: [
        { title: "Video: Teach me how to breastfeed", link: "https://vimeo.com/275183614" },
        { title: "Video: How to latch your baby", link: "https://www.healthlinkbc.ca/pregnancy-parenting/parenting-babies-0-12-months/breastfeeding/latching-your-baby-video" },
        { title: "Video: Feeding cues & behaviours", link: "https://www.healthlinkbc.ca/pregnancy-parenting/parenting-babies-0-12-months/feeding-your-baby/baby-feeding-cues-video" },
        { title: "Video: Breastfeeding positions", link: "https://www.healthlinkbc.ca/pregnancy-parenting/parenting-babies-0-12-months/breastfeeding/breastfeeding-positions" },
        { title: "Video: Hand expressing milk", link: "https://www.healthlinkbc.ca/pregnancy-parenting/parenting-babies-0-12-months/breastfeeding/get-started-expressing-breastmilk" },
        { title: "Video: Cup feeding and other methods", link: "https://www.healthlinkbc.ca/pregnancy-parenting/parenting-babies-0-12-months/feeding-your-baby/cup-feeding-baby-breast-milk-or" }
      ]
    },
    {
      title: "Postpartum Depression",
      items: [
        { title: "Pacific Post-Partum Support Society", link: "https://postpartum.org" },
        { title: "Coping with Depression During Pregnancy and Following the Birth", link: "https://www.heretohelp.bc.ca/sites/default/files/coping-with-depression-during-pregnancy-and-following-birth-a-cognitive-behaviour-therapy-based-self-management-guide-for-women.pdf" },
        { title: "Postpartum Depression - Supportive Tools and Tips for Coping (Here to Help)", link: "https://www.heretohelp.bc.ca/infosheet/postpartum-depression" },
        { title: "Tool 8: Sleep (New Moms) - Anxiety Canada", link: "https://www.anxietycanada.com/articles/tool-8-sleep-new-moms/" }
      ]
    },
    {
      title: "Caring for Your Newborn",
      items: [
        { title: "Caring for Your Newborn", link: "http://www.bcwomens.ca/health-info/pregnancy-parenting/caring-for-your-newborn" },
        { title: "Newborns (0 – 4 months) - Healthy Sleep for Kids", link: "https://www.bcchr.ca/healthysleepforkids/newborns" },
        { title: "Safer Sleep for My Baby (PDF)", link: "/Assets/safer-sleep-for-my-baby.pdf", isDownloadable: true },
        { title: "Normal Newborn Behaviour - Ontario Midwives (PDF)", link: "/Assets/NB behaviour.pdf", isDownloadable: true },
        { title: "Newborn Blood Screening/Critical Congenital Heart Defect Screening", link: "https://www.newbornscreening.on.ca/en" },
        { title: "Newborn Tests and Procedures - BC Women's", link: "http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/after-the-birth/newborn-tests-and-procedures" }
      ]
    },
    {
      title: "Contraception and Family Planning",
      items: [
        { title: "Postpartum Contraception (PDF)", link: "/Assets/Postpartum-Contraception.pdf", isDownloadable: true },
        { title: "Contraception - How Effective is my Birth Control (SOGC) (PDF)", link: "/Assets/Its-a-Plan-How-Effective-is-my-Birth-Control-E-1.pdf", isDownloadable: true }
      ]
    }
  ];

  return (
    <div>
      <Header />
      <section className="py-16 bg-white text-gray-800">
        <div className="container mx-auto px-4 md:px-12">
          <h2 className="text-4xl font-bold mb-12 text-center">Postpartum Care Resources</h2>
          <div className="grid grid-cols-1 gap-8">
            {resources.map((resource, index) => (
              <div key={index} className="rounded-lg shadow-lg p-6 bg-gray-100">
                <h3 className="text-2xl font-semibold mb-4">{resource.title}</h3>
                {resource.description && (
                  <p className="text-base text-gray-700 mb-4">{resource.description}</p>
                )}
                {resource.items ? (
                  <ul className="list-disc list-inside space-y-2">
                    {resource.items.map((item, itemIndex) => (
                      <li key={itemIndex}>
                        {item.isDownloadable ? (
                          <a href={item.link} download className="text-blue-500 hover:underline">
                            {item.title}
                          </a>
                        ) : (
                          <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                            {item.title}
                          </a>
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <a href={resource.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                    {resource.linkText}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PostpartumCare;
