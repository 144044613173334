import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// Removed logo import to prevent issues during npm start
// import logo from '../assets/logo.svg'; // Placeholder for your logo file path

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-blue-400 to-blue-200 shadow-md py-4">
      <div className="container mx-auto flex justify-between items-center px-4">
        {/* Logo and Site Name */}
        <div className="flex items-center">
          {/* Removed logo image */}
          {/* <img src={logo} alt="Your Practice Logo" className="w-10 h-10 mr-2" /> */}
          <span className="text-2xl font-bold text-gray-800">Mitra Sadeghipour</span>
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-gray-800 focus:outline-none" aria-label="Toggle Menu">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              ></path>
            </svg>
          </button>
        </div>

        {/* Navigation Links */}
        <nav
          className={`absolute top-16 left-0 w-full bg-white shadow-md md:static md:bg-transparent md:shadow-none flex-col md:flex-row md:flex items-center justify-center space-y-4 md:space-y-0 md:space-x-4 ${
            isMenuOpen ? 'flex' : 'hidden'
          } md:flex`}
        >
          <ul className="flex flex-col md:flex-row md:ml-auto space-y-4 md:space-y-0 md:space-x-4 mt-4 md:mt-0">
            <li>
              <Link to="/" className="text-gray-800 hover:text-blue-600" aria-label="Navigate to Home">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-gray-800 hover:text-blue-600" aria-label="Navigate to About">
                About
              </Link>
            </li>
            <li>
              <Link to="/services" className="text-gray-800 hover:text-blue-600" aria-label="Navigate to Services">
                Services
              </Link>
            </li>
            <li>
              <Link to="/resources" className="text-gray-800 hover:text-blue-600" aria-label="Navigate to Resources">
                Resources
              </Link>
            </li>
            <li>
              <Link to="/contact" className="text-gray-800 hover:text-blue-600" aria-label="Navigate to Contact">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
