// Import necessary dependencies and components
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

// SupportAndResources component for support and resources page
const SupportAndResources = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header component */}
      <Header />
      
      {/* Main content area */}
      <main className="flex-grow container mx-auto p-4">
        <h1 className="text-4xl font-bold text-center my-8">Support and Resources</h1>
        <p className="text-lg my-4 text-center">
          Find comprehensive support resources, including counseling, milk banks, family planning, and mental health support. This section aims to provide you with essential tools and contacts for you and your family's well-being.
        </p>

        {/* Mental Health Resources Section */}
        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Mental Health Support</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="https://postpartum.org" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Pacific Post-Partum Support Society
              </a>
            </li>
            <li>
              <a href="https://www.heretohelp.bc.ca/infosheet/postpartum-depression" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Postpartum Depression - Supportive Tools and Tips for Coping
              </a>
            </li>
            <li>
              <a href="https://www.anxietycanada.com/articles/tool-8-sleep-new-moms/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Tool 8: Sleep (New Moms) - Anxiety Canada
              </a>
            </li>
            <li>
              <a href="/Assets/Postpartum-Contraception.pdf" className="text-blue-500 hover:underline" download>
                Coping with Depression During Pregnancy and Following the Birth (PDF)
              </a>
            </li>
          </ul>
        </section>

        {/* Milk Banks and Breastfeeding Support */}
        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Milk Banks and Breastfeeding Support</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="http://www.bcwomens.ca/our-services/labour-birth-post-birth-care/milk-bank" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                BC Women's Hospital Milk Bank
              </a>
            </li>
            <li>
              <a href="https://www.hmbana.org" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Human Milk Banking Association of North America
              </a>
            </li>
            <li>
              <a href="http://www.hm4hb.net" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Human Milk 4 Human Babies Network
              </a>
            </li>
            <li>
              <a href="http://www.bcwomens.ca/health-info/pregnancy-parenting/breastfeeding-your-baby" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Breastfeeding Your Baby - BC Women's Hospital
              </a>
            </li>
          </ul>
        </section>

        {/* Parenting and Newborn Care */}
        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Parenting and Newborn Care</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="https://www.healthlinkbc.ca/pregnancy-parenting/parenting-babies-0-12-months" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Parenting Babies 0 – 12 Months - HealthLink BC
              </a>
            </li>
            <li>
              <a href="https://caringforkids.cps.ca/handouts/pregnancy-and-babies/schedule_of_well_child_visits" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Schedule of Well-Child Visits - Caring for Kids
              </a>
            </li>
            <li>
              <a href="https://www.healthlinkbc.ca/sites/default/files/safer-sleep-for-my-baby.pdf" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Safer Sleep for My Baby (PDF)
              </a>
            </li>
            <li>
              <a href="https://dontshake.org/purple-crying" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Period of PURPLE Crying
              </a>
            </li>
            <li>
              <a href="https://caringforkids.cps.ca/handouts/pregnancy-and-babies/your-babys-skin" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Newborn Skin Care - Caring for Kids
              </a>
            </li>
            <li>
              <a href="/Assets/Flat-Head-Information-Sheet.pdf" className="text-blue-500 hover:underline" download>
                Flat Head Information Sheet (PDF)
              </a>
            </li>
          </ul>
        </section>

        {/* Family Planning and Contraception */}
        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Family Planning and Contraception</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="/Assets/Its-a-Plan-How-Effective-is-my-Birth-Control-E-1.pdf" className="text-blue-500 hover:underline" download>
                How Effective is My Birth Control (PDF)
              </a>
            </li>
            <li>
              <a href="/Assets/Postpartum-Contraception.pdf" className="text-blue-500 hover:underline" download>
                Postpartum Contraception (PDF)
              </a>
            </li>
            <li>
              <a href="https://www.sexandu.ca" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Sex & U - Information on Contraception and Family Planning
              </a>
            </li>
          </ul>
        </section>

        {/* Finding a Family Physician */}
        <section className="my-10">
          <h2 className="text-3xl font-semibold my-6">Finding a Family Physician</h2>
          <ul className="list-disc list-inside my-4">
            <li>
              <a href="https://bccfp.bc.ca/for-the-public/find-a-family-doctor/" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                BC College of Family Physicians - Find a Family Doctor
              </a>
            </li>
            <li>
              <a href="https://www.healthlinkbc.ca/health-connect-registry" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Health Connect Registry - Find a Doctor in BC
              </a>
            </li>
            <li>
              <a href="https://pathwaysmedicalcare.ca" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Pathways Medical Care Directory
              </a>
            </li>
            <li>
              <a href="https://www.findadoctorbc.ca" className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">
                Find a Doctor BC - Family Doctors Accepting Patients
              </a>
            </li>
          </ul>
        </section>
      </main>
      
      {/* Footer component */}
      <Footer />
    </div>
  );
};

export default SupportAndResources;