import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import prenatalCareImage from '../assets/prenatalcare.jpeg'; // Import the prenatal care image
import deliveryImage from '../assets/delivery.jpeg'; // Import the delivery image
import postnatalCareImage from '../assets/postnatalcare.jpeg'; // Import the postnatal care image
import OceanImage from '../assets/ocean.jpeg'; // Import the ocean background image
import supportResourcesImage from '../assets/support.jpeg'; // Import the support and resources image
import Stages from '../assets/stages.jpeg'; // Import the stages image]
const Resources = () => {
  // Resources array with updated links and background images
  const resources = [
    {
      title: "Pregnancy Stages",
      description: "Detailed information and resources for each stage of pregnancy.",
      link: "/pregnancystages",
      linkText: "Access",
      backgroundImage: Stages
    },
    {
      title: "Prenatal Care Resources",
      description: "Discover resources to help guide you through your prenatal care journey.",
      link: "/prenatalcare",
      linkText: "Access",
      backgroundImage: prenatalCareImage
    },
    {
      title: "Labour and Birth Resources",
      description: "Comprehensive resources to support you during labour and birth, including pain relief options, induction, and preparation tips.",
      link: "/labourandbirth",
      linkText: "Access",
      backgroundImage: deliveryImage
    },
    {
      title: "Postpartum Care Resources",
      description: "Information to support recovery and the health of you and your baby after birth.",
      link: "/postpartumcare",
      linkText: "Access",
      backgroundImage: postnatalCareImage
    },
    {
      title: "Support and Resources",
      description: "Find comprehensive support resources, including counseling, milk banks, family planning, and mental health support.",
      link: "/supportandresources",
      linkText: "Access",
      backgroundImage: supportResourcesImage,
      backgroundPosition: 'right' // Set background position to right for this specific image
    }
  ];

  return (
    <div style={{ backgroundImage: `url(${OceanImage})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>
      <Header />
      
      {/* Resources Section */}
      <section className="py-16 text-gray-800">
        <div className="container mx-auto text-center px-4 md:px-12">
          <h2 className="text-4xl font-bold mb-12">RESOURCES</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {resources.map((resource, index) => (
              <div
                key={index}
                className="h-full w-full rounded-lg shadow-lg flex flex-col justify-between"
                style={{
                  backgroundImage: `url(${resource.backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: resource.backgroundPosition || 'center' // Default to center unless specified
                }}
              >
                <div className="flex-grow p-6" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                  <h3 className="font-bold text-xl mb-4 text-white">{resource.title}</h3>
                  <p className="text-base text-white">{resource.description}</p>
                </div>
                <div className="mt-6 p-6" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                  <Link 
                    to={resource.link} 
                    className="inline-block w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 rounded transition duration-300"
                  >
                    {resource.linkText}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Main Footer Component */}
      <Footer />
    </div>
  );
};

export default Resources;
