// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-blue-400 to-blue-200 text-white py-12 w-full">
      <div className="container mx-auto px-6 md:px-12">
        <div className="flex flex-wrap justify-between text-center md:text-left">
          {/* Practice Information */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h2 className="text-xl md:text-2xl font-bold mb-2">Mitra Sadeghipour</h2>
            <p className="text-sm md:text-base">Email: info@carnationmidwifery.com</p>
            <p className="text-sm md:text-base">Phone: +1 (604) 363-8158</p>
            {/* Optional Address */}
            {/* <p className="text-sm md:text-base">Address: 1234 Wellness St, Vancouver, BC</p> */}
          </div>

          {/* Navigation Links */}
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-lg md:text-xl font-semibold mb-2">Navigation</h3>
            <ul className="text-sm md:text-base">
              <li><Link to="/" className="hover:underline">Home</Link></li>
              <li><Link to="/about" className="hover:underline">About</Link></li>
              <li><Link to="/services" className="hover:underline">Services</Link></li>
              <li><Link to="/resources" className="hover:underline">Resources</Link></li>
              <li><Link to="/contact" className="hover:underline">Contact</Link></li>
              {/* Removed "Testimonials" and "Account" links as per your requirements */}
            </ul>
          </div>

         
        </div>
        <div className="mt-8 text-center">
          <p className="text-sm md:text-base">© 2024 Mitra Sadeghipour. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
